<template>
  <b-row class="pt-1">
    <b-col md="4" class="pt-1">
      <b-button
        :href="web_link"
        target="_blank"
        block
        variant="outline-primary"
        class="pb-1"
      >
        <span class="align-middle text-change">DAPP Website</span>
      </b-button>
    </b-col>
    <b-col md="8" class="pt-1">
      <b-link
        v-if="social_data.twitter"
        :href="social_data.twitter"
        target="_blank"
        class="px-3"
      >
        <feather-icon
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          title="Twitter"
          variant="outline-primary"
          icon="TwitterIcon"
          class="mr-50 ml-50"
          size="30"
        />
      </b-link>
      <b-link
        v-if="social_data.facebook"
        :href="social_data.facebook"
        target="_blank"
        class="pr-3"
      >
        <feather-icon
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          title="Facebook"
          variant="outline-primary"
          icon="FacebookIcon"
          class="mr-50"
          size="30"
        />
      </b-link>
      <b-link
        v-if="social_data.telegram"
        :href="social_data.telegram"
        target="_blank"
        class="pr-3"
      >
        <feather-icon
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          title="Telegram"
          variant="outline-primary"
          icon="SendIcon"
          class="mr-50"
          size="30"
        />
      </b-link>
      <b-link
        v-if="social_data.discord"
        :href="social_data.discord"
        target="_blank"
        class="pr-3"
      >
        <feather-icon
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          title="Discord"
          variant="outline-primary"
          icon="DiscIcon"
          class="mr-50"
          size="30"
        />
      </b-link>
      <b-link
        v-if="social_data.reddit"
        :href="social_data.reddit"
        target="_blank"
        class="pr-3"
      >
        <feather-icon
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          title="Reddit"
          variant="outline-primary"
          icon="CircleIcon"
          class="mr-50"
          size="30"
        />
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BLink, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink
    // apexchart: VueApexCharts,
    // BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ['social_data', 'web_link']
};
</script>