<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="8">
          <div class="d-flex justify-content-start">
            <b-col md="2">
              <b-avatar
                :src="singleDappData.dapp_logo"
                :text="singleDappData.dapp_name"
                size="125px"
                rounded
              />
            </b-col>
            <b-col md="10">
                <b-row>
                  <b-col md="3">
                    <h3>{{ singleDappData.dapp_name }}</h3>
                  </b-col>
                  <b-col md="2">
                    <span class="badge badge-success">{{singleDappData.dapp_status}}</span>
                  </b-col>
                </b-row>
              <b-col md="12" class="pt-2">
                <span class="align-middle"
                  >{{ singleDappData.dapp_short_description }}
                </span>
              </b-col>
            </b-col>
          </div>
          <!-- <b-card>
          <b-row>
            <b-col md="10">
              <span>{{ singleDappData.dapp_short_description }}</span>
            </b-col>
          </b-row>
        </b-card> -->
          <b-row v-if="singleDappData.dapp_contract_address != null">
            <b-col cols="10" xl="10">
              <div class="mt-1">
                <div class="m-0">
                  <small>DAPP contract address</small>
                  <div class="mr-1">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Binance Smart Chain Contract"
                        :value="singleDappData.dapp_contract_address"
                        disabled
                      />
                    </b-input-group>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="2" xl="2" class="mt-1">
              <b-avatar variant="light-primary" rounded class="pointer mt-2">
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  @click="doCopy(singleDappData.dapp_contract_address)"
                />
              </b-avatar>
            </b-col>
          </b-row>
          <social-links
            :social_data="singleDappData.dapp_social_links"
            :web_link="singleDappData.dapp_web_link"
            class="pb-3"
          />
        </b-col>
        <b-col md="4">
          <b-badge v-if="!is_mobilesize">
            <volume-detail-card :volume_data="singleDappData.dapp_dex_data" class="pt-2" />
          </b-badge>
          <volume-detail-card-mobile v-else :volume_data="singleDappData.dapp_dex_data" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col md="6">
              <b-card>
                <h3>About</h3>
                <p v-html="singleDappData.dapp_long_description"></p>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-card>
                <h3>How does it works</h3>
                <p v-html="singleDappData.dapp_how_it_works"></p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BAvatar,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import VolumeDetailCard from "./dappdetail/VolumeDetailCard.vue";
import SocialLinks from "./dappdetail/SocialLinks.vue";
import { mixinList } from "@/mixins/mixinList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VolumeDetailCardMobile from "./dappdetail/VolumeDetailCardMobile.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    VolumeDetailCard,
    SocialLinks,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BBadge,
    VolumeDetailCardMobile
  },
  mixins: [mixinList],
  data() {
    return {};
  },
  mounted() {
    this.getdappData();
  },
  methods: {
    doCopy(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "DAPP contract address copied",
              icon: "BellIcon",
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e + "Can not copy!",
              icon: "BellIcon",
            },
          });
        }
      );
    },
    getdappData() {
      let id = this.$route.params.id;
      this.$store.dispatch("FETCH_SINGLE_DAPP_DATA", id);
    },
  },
  computed: {
    singleDappData() {
      return this.$store.state.dapp.singleDappData;
    },
  },
};
</script>

<style>
.online {
  color: #86bb71;
}
</style>
