<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6" class="pl-3">
          <h5>Balance </h5>
        </b-col>
        <b-col md="6" class="pr-1">
          <div class="d-flex justify-content-end mb-1 col-12 text-end">
            <h5>{{volume_data.balance ? volume_data.balance : ''}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" class="pl-3">
          <h5>Daily Users</h5>
        </b-col>
        <b-col md="6" class="pr-1">
          <div class="d-flex justify-content-end mb-1 col-12 text-end">
            <h5>{{volume_data.daily_usage}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" class="pl-3">
          <h5>Volume 24h</h5>
        </b-col>
        <b-col md="6" class="pr-1">
          <div class="d-flex justify-content-end mb-1 col-12 text-end">
            <h5>{{volume_data.volume_24h}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" class="pl-3">
          <h5>Volume 7d</h5>
        </b-col>
        <b-col md="6" class="pr-1">
          <div class="d-flex justify-content-end mb-1 col-12 text-end">
            <h5>{{volume_data.volume_7d}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" class="pl-3">
          <h5>Release Date</h5>
        </b-col>
        <b-col md="6" class="pr-1">
          <div class="d-flex justify-content-end mb-1 col-12 text-end">
            <h5>Dec 29, 2017</h5>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

export default {
  props: ['volume_data'],
  components: {
    BRow,
    BCol,
    BCard,
  },
};
</script>