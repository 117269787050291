<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8" xl="10">
          <div class="mt-1">
            <div class="m-0">
              <h5>Balance</h5>
            </div>
          </div>
        </b-col>
        <b-col cols="4" xl="2" class="mt-1">
          <div class="d-flex justify-content-end col-12 text-end">
            <h5>{{ volume_data.balance ? volume_data.balance : "N/A" }}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8" xl="10">
          <div class="mt-1">
            <div class="m-0">
              <h5>Daily Users</h5>
            </div>
          </div>
        </b-col>
        <b-col cols="4" xl="2" class="mt-1">
          <div class="d-flex justify-content-end col-12 text-end">
            <h5>{{ volume_data.daily_usage ? volume_data.daily_usage : "N/A" }}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8" xl="10">
          <div class="mt-1">
            <div class="m-0">
              <h5>Volume 24h</h5>
            </div>
          </div>
        </b-col>
        <b-col cols="4" xl="2" class="mt-1">
          <div class="d-flex justify-content-end col-12 text-end">
            <h5>{{ volume_data.volume_24h ? volume_data.volume_24h : "N/A" }}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8" xl="10">
          <div class="mt-1">
            <div class="m-0">
              <h5>Volume 7d</h5>
            </div>
          </div>
        </b-col>
        <b-col cols="4" xl="2" class="mt-1">
          <div class="d-flex justify-content-end col-12 text-end">
            <h5>{{ volume_data.volume_7d ? volume_data.volume_7d : "N/A" }}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" xl="10">
          <div class="mt-1">
            <div class="m-0">
              <h5>Release Date</h5>
            </div>
          </div>
        </b-col>
        <b-col cols="6" xl="2" class="mt-1">
          <div class="d-flex justify-content-end col-12 text-end">
            <h5>Dec 29, 2017</h5>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

export default {
  props: ["volume_data"],
  components: {
    BRow,
    BCol,
    BCard,
  },
};
</script>